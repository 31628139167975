.testimonials-container {
    min-height: 100vh;
  }
  
  .header-section {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .breadcrumb {
    background: transparent;
    margin-bottom: 0;
  }
  
  .breadcrumb-item a {
    color: #fff;
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #ffa500;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 3rem;
    position: relative;
  }
  
  .testimonials-heading {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
  }
  
  .testimonials-heading::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: #ffa500;
  }
  
  .video-card {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 100%;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .duration-badge {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
  }
  
  /* First row videos - larger size */
  .col-md-6 .video-wrapper {
    padding-bottom: 60%; /* Slightly taller for first row videos */
  }
  
  @media (max-width: 768px) {
    .col-md-6,
    .col-md-4 {
      margin-bottom: 2rem;
    }
  
    /* Stack videos vertically on mobile */
    .video-wrapper {
      padding-bottom: 56.25%;
    }
  }
  
  