.heroSection {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .heroContent {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 0 20px;
  }
  
  .heroTitle {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .heroSubtitle {
    font-size: 1.5rem;
    text-align: left;  }
  
  @media (max-width: 768px) {
    .heroTitle {
      font-size: 2rem;
    }
  
    .heroSubtitle {
      font-size: 1rem;
    }
  }
  
  