

/* Base navbar styling */
.navbar {
  transition: all 0.3s ease;
  z-index: 1030;

}

.navbar-brand img {
 height: 150px; /* Adjust height if needed */
  width: 150px;
}


.nav-link {
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
  padding: 0.5rem 0.5rem !important;
  font-weight: 900;
  color: #f1f1f1;
}

.nav-link:hover {
  color: #ff7f50 !important;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #ff7f50;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover::after {
  width: 70%;
}

/* Download button styling */
.download-btn {
  background-color: #ff7f50;
  color: white;
  font-weight: 600;
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #ff6a3c;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
/* Navbar fixed at the top */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevents scrolling */
  height: auto;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}



/* Navbar container */
.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between logo and links */
  flex-direction: row; /* Aligns items in a row */
  width: 100%;
  padding: 0.5rem 1rem;
  white-space: nowrap; /* Prevents wrapping */
}




/* Navbar links */
.navbar-nav {
  display: flex;
  flex-direction: row; /* Aligns links in a row */
  align-items: center;
  gap: 1rem; /* Adjust spacing */
  flex-wrap: nowrap; /* Prevents items from moving to the next line */
  overflow: hidden;
}



/* Navbar links scrollable area */
.navbar-collapse {
  overflow-y: auto; /* Make the links scrollable */
  flex-grow: 1; /* Allow the links to take up remaining space */
}

/* Prevent body scrolling when navbar is open (for mobile) */
body.menu-open {
  overflow: hidden;
}

/* Mobile menu styling */
@media (max-width: 991.98px) {


  .navbar-collapse {
    position: fixed;
    top: 0;
    right: -280px; /* Start off-screen */
    bottom: 0;
    width: 280px;
    height: 100vh !important;
    padding: 2rem 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: -5px 0 15px rgb(177, 175, 175);
    transition: right 0.3s ease;
    display: block !important;
    z-index: 1040;
    overflow-y: auto;
  }

  .navbar-collapse.show {
    right: 0; /* Slide in from right */
  }

  .mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(194, 199, 206, 0.5);
    z-index: -1;
  }

  .navbar-collapse.show .mobile-menu-overlay {
    display: block;
  }

  .navbar-nav {
    margin-top: 2rem;
  }

  .nav-item {
    margin: 0.5rem 0;
  }

  .nav-link {
    padding: 0.75rem 0 !important;
    font-size: 1.1rem;
    border-bottom: 1px solid #f1f1f1;
  }

  .nav-link::after {
    display: none;
  }

  /* Prevent body scrolling when menu is open */
  body.menu-open {
    overflow: hidden;
  }

  /* Hamburger animation */
  .navbar-toggler {
    border: none;
    padding: 0.5rem;
    transition: all 0.3s ease;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }

  .navbar-toggler-icon {
    transition: all 0.3s ease;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon {
    transform: rotate(180deg);
  }
}

