

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section {
    height: 100vh; /* Changed from 60vh to 100vh for full-screen on mobile */
  }

  .hero-content h1 {
    font-size: 2rem;
  }
}




/* Services Section */
.services-section .card {
  transition: transform 0.3s ease;
}

.services-section .card:hover {
  transform: translateY(-5px);
}

/* Stats Section */
.stats-section {
 
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.hero-stats {
  position: relative;
  overflow: hidden;
}

.stats-section {
  position: relative;
  background-attachment: fixed;
}

.stat-item {
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-item h2 {
  font-size: calc(2rem + 1vw);
}

@media (max-width: 768px) {
  .stat-item h2 {
    font-size: calc(1.5rem + 1vw);
  }

  .stat-item p {
    font-size: 0.8rem;
  }
}



/* Clients Section */
.client-logo {
  transition: transform 0.3s ease;
} 

.client-logo:hover {
  transform: scale(1.05);
}



.footer-section {
  background-color: #004b87;
  padding: 20px 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 14px;
  opacity: 0.8;
}

.footer-links a:hover {
  color: #ffc107;
  opacity: 1;
}

.contact-info p {
  margin-bottom: 10px;
  font-size: 14px;
  opacity: 0.8;
}

.contact-info a:hover {
  color: #ffc107 !important;
}

.read-more {
  display: inline-block;
  padding: 5px 0;
  font-weight: 500;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #fff !important;
}

.footer-bottom {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

@media (max-width: 768px) {
  .footer-section {
    text-align: center;
  }

  .footer-bottom .text-end {
    text-align: center !important;
    margin-top: 10px;
  }
}



/*footer about content*/
.Modal {
  position: fixed;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(190, 107, 107);
  border-radius: 10px;
  max-width: 90%;
  width: 800px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 0; /* Remove default padding */
}

.modal-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 0;
  /* Ensure content starts from the top */
  height: calc(100% - 70px); /* Subtract footer height */
}

.modal-content > div {
  /* Add padding to inner content */
  padding: 0;
  margin: 0;
}

.modal-content img {
  max-width: 100%;
  height: auto;
  display: block;
}

.modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: right;
  background: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 4px; /* Fixed height for footer */
}

.modal-close-button {
  padding: 10px 20px;
  background-color: #ffcc00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  width:'20px'
}

.modal-close-button:hover {
  background-color: #e6b800;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}
.clickable {
  cursor: pointer;
}


@media (max-width: 768px) {
  .Modal {
    width: 95%;
    height: 85vh;
  }

  .modal-content {
    height: calc(100% - 60px);
  }

  .modal-footer {
    height: 60px;
    padding: 10px 15px;
  }
}
/* 

.imgleft{
  border: 10px solid lightslategrey;
  border-radius: 60%;
}
.rightdiv{
  margin: 80px;
  padding-left: 60px;
}
.rightpara{
 
    text-align:justify;
    line-height: 40px;
    font-size: 20px;
  
}
 /* Profile Image Container */
.profile-img-container {
  width: 300px; /* Fixed width */
  height: 300px; /* Fixed height */
  display: flex;
  justify-content: center; /* Centers image horizontally */
  align-items: center; /* Centers image vertically */
  overflow: hidden; /* Prevents overflow */
  border-radius: 50%; /* Circular shape */
  border: 10px solid #d17a17; /* Border for styling */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  margin: 0 auto; /* Center the container */

}

/* Image Styling */
.profile-img {
  width: 100%;
  height: 100%;
  /* object-fit: fill; */
  object-fit: contain;
  /* object-fit: cover; */
  /* object-fit: scale-down; */
  border-radius: 50%;
  

}

/* Profile Name Styling */
.profile-name {
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
  margin-top: 10px;
  text-align: center;
}


/* Underline properly aligned under About */
.underline {
  width: 80px;
  height: 4px;
  background-color: #007bff;
  margin-top: 5px; /* Adjusted spacing */
}



/* About Text Styling */
.about-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align for all screen sizes */
  text-align: justify;
  font-size: 22px;
}
.about-heading{
  padding-bottom: 20px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .profile-img-container {
    width: 150px;
    height: 150px;
  }
  
  .profile-name {
    font-size: 18px;
  }
  
  .about-text {
    font-size: 16px;
   
  }
  .underline {
    width: 70px;
    margin-left: 140px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  
}
